<template>
    <b-card title="Matches">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-between">
            <!-- <b-form-group v-if="this.showAdd">
                <div class="d-flex align-items-center">
                    <b-button
                        id="toggle-btn"
                        v-b-modal.modal-prevent-closing
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="addNew()"
                    >
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Add New</span>
                    </b-button>
                </div>
            </b-form-group> -->
            <b-form-group class="justify-content-end">
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                    />
                </div>
            </b-form-group>
        </div>
    
        <!-- table -->
        <div v-if="!userLoading && !userError">
            <vue-good-table
                :columns="columns"
                :rows="rows"
                :rtl="direction"
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm  
                }"
                :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
                styleClass="vgt-table striped condensed no-outline"
            >
        
                <template
                    slot="table-row"
                    slot-scope="props"
                >
        
                    <!-- Column: Sender -->
                    <span
                        v-if="props.column.field === 'sender'"
                        class="text-nowrap"
                    >
                        <router-link :to="{ path: '/admin/user/profile/' + props.row.sender_profile.personal_info.profile_id }">
                            <span class="profile-container">
                                <span class="text-nowrap">{{ props.row.sender_profile.first_name }} {{ props.row.sender_profile.last_name }}</span>
                                <span class="text-nowrap">{{ props.row.sender_profile.personal_info.profile_id }}</span>
                            </span>
                        </router-link>
                    </span>

                    <!-- Column: Receiver -->
                    <span
                        v-if="props.column.field === 'receiver'"
                        class="text-nowrap"
                    >
                        <router-link :to="{ path: '/admin/user/profile/' + props.row.receiver_profile.personal_info.profile_id }">
                            <span class="profile-container">
                                <span class="text-nowrap">{{ props.row.receiver_profile.first_name }} {{ props.row.receiver_profile.last_name }}</span>
                                <span class="text-nowrap">{{ props.row.receiver_profile.personal_info.profile_id }}</span>
                            </span>
                        </router-link>
                    </span>
                    
                    <!-- Column: Index -->
                    <span v-else-if="props.column.field === 'index'">
                        <span>{{props.row.originalIndex+1}}</span>
                    </span>

                    <!-- Column: Payment Status -->
                    <!-- @click="updatePaymentStatus(props.row.matched_payment_status, 'pending')"  -->
                    <!-- @click="updatePaymentStatus(props.matched_payment_status, 'complete')" -->
                    <span v-else-if="props.column.field === 'paymentStatus'">
                        <span v-if="props.row.matched_payment_status === null"><b-badge pill variant="light-danger">None</b-badge></span>
                        <span v-else-if="props.row.matched_payment_status === 'complete'"><b-badge pill variant="light-success">Paid</b-badge></span>
                        <span v-else ><b-badge pill variant="light-primary">Pending</b-badge></span>
                    </span>

                    <!-- Column: Timestamp -->
                    <span v-else-if="props.column.field === 'timestamp'">
                        <span>{{ getTimeStamp(props.row.timestamp) }}</span>
                    </span>
            
                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
        
                <!-- pagination -->
                <template
                    slot="pagination-bottom"
                    slot-scope="props"
                >
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                                Showing 1 to
                            </span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['3','5','10','50','100']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="(value)=>props.pageChanged({currentPage:value})"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </div>

        <div v-else class="loading-user">
            <img src="../../../../assets/images/logo/logo.png" alt="">
            <div class="load-6">
                <div class="letter-holder">
                    <div class="l-1 letter">P</div>
                    <div class="l-2 letter">l</div>
                    <div class="l-3 letter">e</div>
                    <div class="l-4 letter">a</div>
                    <div class="l-5 letter">s</div>
                    <div class="l-6 letter mr-1">e</div>
                    <div class="l-7 letter">W</div>
                    <div class="l-8 letter">a</div>
                    <div class="l-9 letter">i</div>
                    <div class="l-10 letter">t</div>
                    <div class="l-11 letter">.</div>
                    <div class="l-12 letter">.</div>
                    <div class="l-13 letter">.</div>
                </div>
            </div>
        </div>
    </b-card>
</template>
  
<script>
import {
BAvatar,BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard,
BModal,VBModal,BCardText, BFormTextarea
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import moment from 'moment';

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BCard,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BModal,
        vSelect,
        BCardText,
        BFormTextarea
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },

    data() {
        return {
            nameState: null,
            addNewMode:false,
            updateStatusMode: false,
            popupActive:true,
            status: {
                id: "",
                status: ""
            },
            modify:{
                id: null,
                name: null,
                district: null,
                country: null
            },
            country_id:null,
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: '#',
                    field: 'index',
                    type:'number',
                    tdClass: 'text-center ',
                    thClass:'text-center'
                },
                {
                    label: 'First User',
                    field: 'sender',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Second User',
                    field: 'receiver',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Payment Status',
                    field: 'paymentStatus',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Timestamp',
                    field: 'timestamp',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
            ],
            showAdd:true,
            showEdit:false,
            showDelete:false,
            rows: [],
            searchTerm: '',
        }
    },

    computed: {
        ...mapState('hearts', ["users","userError","userLoading"]),
    
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },

    watch: {
        users(newValue, oldValue) {
            this.rows=this.users
        },

        userError(newValue, oldValue) {},

        userLoading(newValue, oldValue) {}
    },

    created() {
        this.$store.dispatch('hearts/fetchMatches')
        this.rows = this.users
        let permissions=JSON.parse(localStorage.getItem('permissions'))
        //   this.showAdd=permissions.includes("add_area")
        //   this.showEdit=permissions.includes("change_area")
        //   this.showDelete=permissions.includes("delete_area")
        if(this.showEdit||this.showDelete){
            this.columns.push({
                label: 'Action',
                field: 'action',
                tdClass: 'text-center',
                thClass:'text-center',
                sortable: false,
            })
        }
    },

    methods:{
        getTimeStamp(time) {
            return moment(time).format('MMMM Do YYYY, h:mm:ss a')
        },

        resetModal() {
            this.nameState = null
        }
    }
}
</script>
  
<style lang="scss" >
.no-outline{
    border:none !important;
}

.f20{
    font-size: 8px !important;
}

.vs__dropdown-menu {
  height: 200px;
}

.profile-container {
    display: flex;
    flex-direction: column
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>